import React from 'react'
import {Box, Typography} from '@mui/material'
import {useTheme} from '@mui/material/styles'

const AppInfo: React.FC = () => {
    const theme = useTheme()

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: 600,
                margin: 'auto',
                padding: 2,
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: '#f4f4f9',
            }}
        >
            <Typography variant="body1">
                <strong>Env:</strong>{' '}
                {process.env.REACT_APP_ENV || 'Not Defined'}
            </Typography>
            <Typography variant="body1">
                <strong>App Version :</strong>{' '}
                {process.env.REACT_APP_VERSION || 'Not Defined'}
            </Typography>
            <Typography variant="body1">
                <strong>Build Time:</strong>{' '}
                {process.env.REACT_APP_BUILDTIME || 'Not Defined'}
            </Typography>
        </Box>
    )
}

export default AppInfo

import React from 'react'
import {Box, Stack, Paper, Typography, Avatar} from '@mui/material'
import {generateLunchdates, Lunchdate} from '../../mock-data/lunchDates'
// Define the Item component with types
interface ItemProps {
    children: React.ReactNode
    colorCode: string
}

const Item = ({children, colorCode}: ItemProps) => (
    <Paper
        sx={{
            padding: 2,
            textAlign: 'left',
            color: 'text.secondary',
            border: `5px solid ${colorCode}`, // Border with colorCode
            borderRadius: 2, // Rounded corners
        }}
        elevation={3}
    >
        {children}
    </Paper>
)

const LunchdateList: React.FC = () => {
    const centerLat = 37.7749 // San Francisco latitude
    const centerLng = -122.4194 // San Francisco longitude
    const radius = 1000 // 1 km
    const lunchdates: Lunchdate[] = generateLunchdates(
        centerLat,
        centerLng,
        radius,
    )

    return (
        <div className="PageContainer">
            <Box sx={{width: '100%', height: '100%', overflowY: 'auto'}}>
                <Stack spacing={2}>
                    {lunchdates.map((lunchdate, index) => (
                        <Item key={index} colorCode={lunchdate.colorCode}>
                            <Stack
                                direction="row"
                                spacing={3}
                                alignItems="center"
                            >
                                {/* Circular Avatar for Host Picture */}
                                <Avatar
                                    alt={lunchdate.name}
                                    src={lunchdate.pictureURL}
                                    sx={{
                                        width: 60, // Adjust the size of the avatar
                                        height: 60, // Adjust the size of the avatar
                                    }}
                                />

                                {/* Text details for Lunch Date */}
                                <Box>
                                    <Typography variant="h6" fontWeight="bold">
                                        {lunchdate.name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {new Date(
                                            lunchdate.dateTime,
                                        ).toLocaleString()}{' '}
                                        {/* Display formatted time */}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        mt={1}
                                    >
                                        {lunchdate.freeText}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Item>
                    ))}
                </Stack>
            </Box>
        </div>
    )
}

export default LunchdateList

import {useEffect} from 'react'

// see https://supabase.com/docs/guides/auth/auth-helpers/auth-ui
// see  https://www.restack.io/docs/supabase-knowledge-supabase-auth-ui-guide

import {Auth} from '@supabase/auth-ui-react'
import {ThemeSupa} from '@supabase/auth-ui-shared'
// for future decoration
// import {getLoginCustomTheme} from './LoginCustomTheme'

import {useTranslation} from 'react-i18next'
// gernman,frenchm spanisch, japanese transaltion for Auth
// see https://github.com/supabase-community/auth-ui/tree/main/localization

import {supabaseClient} from '../../config/supabase'
import {useBottomNav} from '../../hooks/BottomNavControl'

const SignUp = () => {
    const {hideBottomNav} = useBottomNav()

    // language support
    const {t} = useTranslation()

    useEffect(() => {
        hideBottomNav()
    }, [hideBottomNav])

    return (
        <div className="PageContainer">
            <Auth
                supabaseClient={supabaseClient}
                providers={['facebook', 'google']} // Facebook-Provider hinzufügen
                appearance={{theme: ThemeSupa}}
                localization={{
                    variables: {
                        sign_up: {
                            email_label: t('auth.login.email_label'),
                            email_input_placeholder: t(
                                'auth.signup.email_input_placeholder',
                            ),
                            password_label: t('auth.login.password_label'),
                            password_input_placeholder: t(
                                'auth.signup.password_placeholder',
                            ),
                            button_label: t('auth.signup.button_label'),
                            social_provider_text: t(
                                'auth.login.sign-in-with-provider',
                            ),
                            link_text: t('auth.signup.link_text'),
                        },
                        sign_in: {
                            link_text: t('auth.login.linktext'),
                        },
                    },
                }}
                //theme="dark"
                view="sign_up"
            />
        </div>
    )
}

export default SignUp

import {createClient} from '@supabase/supabase-js'

// Define a type for configuration
type Config = {
    supabaseUrl: string
    supabaseKey: string
}

// Define configurations for different environments
const configs: Record<string, Config> = {
    development: {
        supabaseUrl: 'https://khqjubccssuqvxmbzzga.supabase.co',
        supabaseKey: process.env.REACT_APP_SUPABASE_KEY || '',
    },
    staging: {
        supabaseUrl: 'https://bdhtffhnecorypstseng.supabase.co',
        supabaseKey: process.env.REACT_APP_SUPABASE_KEY || '',
    },
    production: {
        supabaseUrl: 'https://avncnkfmxvntommmoblh.supabase.co',
        supabaseKey: process.env.REACT_APP_SUPABASE_KEY || '',
    },
}

// Create and export the Supabase client
export const supabaseClient = (() => {
    // Determine the current environment
    const currentEnv = process.env.REACT_APP_ENV || 'development'

    // Get the corresponding configuration or fallback to development
    const config = configs[currentEnv] || configs.development

    if (!config.supabaseUrl || !config.supabaseKey) {
        throw new Error(
            `Supabase configuration is missing for environment: ${currentEnv}`,
        )
    }

    //console.log('Supabase URL:', config.supabaseUrl);
    //console.log('Supabase Key:', config.supabaseKey );

    return createClient(config.supabaseUrl, config.supabaseKey, {
        auth: {
            persistSession: true, // This ensures sessions are automatically persisted in localStorage
            autoRefreshToken: true, // Enables automatic token refreshing
        },
    })
})()

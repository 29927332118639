import React, {
    createContext,
    useContext,
    useState,
    useMemo,
    ReactNode,
} from 'react'
import {ThemeProvider} from '@mui/material/styles' // Import ThemeProvider from MUI
import {responsiveLightTheme, responsiveDarkTheme} from '../themes/noonoutTheme' // Import the light and dark themes

// Create a context to manage theme state
interface ThemeContextType {
    isDarkMode: boolean
    toggleTheme: () => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

// Create a custom hook to use the theme context
export const useThemeContext = (): ThemeContextType => {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error('useThemeContext must be used within a ThemeProvider')
    }
    return context
}

// Create a provider component for the theme context
interface ThemeProviderProps {
    children: ReactNode
}

export const ThemeProviderWrapper: React.FC<ThemeProviderProps> = ({
    children,
}) => {
    const storedMode = localStorage.getItem('themeMode') || 'light'
    const [isDarkMode, setIsDarkMode] = useState(storedMode === 'dark')

    const toggleTheme = () => {
        setIsDarkMode((prevMode) => {
            const newMode = !prevMode
            localStorage.setItem('themeMode', newMode ? 'dark' : 'light')
            return newMode
        })
    }

    const theme = useMemo(
        () => (isDarkMode ? responsiveDarkTheme : responsiveLightTheme),
        [isDarkMode],
    )

    return (
        <ThemeContext.Provider value={{isDarkMode, toggleTheme}}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    )
}

import {useEffect} from 'react'

import {useNavigate} from 'react-router-dom'
import {Route} from '../../router'

import {useAuth} from '../../hooks/Auth'
import {useBottomNav} from '../../hooks/BottomNavControl'

const Home = () => {
    const navigate = useNavigate()
    const {onboardingStep, session} = useAuth()
    const {showBottomNav} = useBottomNav()

    useEffect(() => {
        // navigate to onboarding page
        // Todo: double check with database , maybe the localstore was deleted
        // but for the first login we're good
        if (session && onboardingStep !== 'done') {
            navigate(Route.ONBOARDING, {replace: true})
            console.log('Home (onboardingStep) -> Onboarding ', onboardingStep)
        } else {
            showBottomNav()
        }
    }, [onboardingStep, session, showBottomNav])

    return <div className="PageContainer">Home</div>
}

export default Home

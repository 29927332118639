import {createBrowserRouter} from 'react-router-dom'
import App from '../App'
import Splashscreen from '../views/Splashscreen/Splashscreen'
import Login from '../views/Login/Login'
import SignUp from '../views/Login/SignUp'
import Onboarding from '../views/Login/Onboarding'
import Map from '../views/Map/Map'
import Search from '../views/Search/Search'
import Home from '../views/Home/Home'
import Chat from '../views/Chat/Chat'
import Profile from '../views/Profile/Profile'
import NotFound from '../views/NotFound/NotFound'
import ProtectedRoute from './protectedRoute'

enum Route {
    ROOT = '/',
    LOGIN = '/login',
    SIGNUP = '/signup',
    ONBOARDING = '/onboarding',
    HOME = '/home',
    SEARCH = '/search',
    MAP = '/map',
    CHAT = '/chat',
    PROFILE = '/profile',
    NOTFOUND = '*',
}

const routes = [
    {
        path: Route.ROOT,
        element: <App />,
        children: [
            // Public route
            {path: Route.ROOT, element: <Splashscreen />},
            {path: Route.LOGIN, element: <Login />},
            {path: Route.SIGNUP, element: <SignUp />},

            // Private routes
            {
                path: Route.HOME,
                element: (
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                ),
            },
            {
                path: Route.SEARCH,
                element: (
                    <ProtectedRoute>
                        <Search />
                    </ProtectedRoute>
                ),
            },
            {
                path: Route.MAP,
                element: (
                    <ProtectedRoute>
                        <Map />
                    </ProtectedRoute>
                ),
            },
            {
                path: Route.CHAT,
                element: (
                    <ProtectedRoute>
                        <Chat />
                    </ProtectedRoute>
                ),
            },
            {
                path: Route.PROFILE,
                element: (
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                ),
            },
            {
                path: Route.ONBOARDING,
                element: (
                    <ProtectedRoute>
                        <Onboarding />
                    </ProtectedRoute>
                ),
            },

            // Catch-all route
            {path: Route.NOTFOUND, element: <NotFound />},
        ],
    },
]

const router = createBrowserRouter(routes, {
    future: {
        v7_startTransition: true,
    },
})

export {router, Route}

import React, {createContext, useContext, useState, ReactNode} from 'react'

// Define the type for the context value
interface BottomNavContextType {
    isVisible: boolean
    showBottomNav: () => void
    hideBottomNav: () => void
}

// Create the context with a default value
const BottomNavContext = createContext<BottomNavContextType | undefined>(
    undefined,
)

// Define props for the provider
interface BottomNavProviderProps {
    children: ReactNode
}

// Provider component
export const BottomNavProvider: React.FC<BottomNavProviderProps> = ({
    children,
}) => {
    const [isVisible, setIsVisible] = useState(true)

    const showBottomNav = () => setIsVisible(true)
    const hideBottomNav = () => setIsVisible(false)

    return (
        <BottomNavContext.Provider
            value={{isVisible, showBottomNav, hideBottomNav}}
        >
            {children}
        </BottomNavContext.Provider>
    )
}

// Custom hook to use the BottomNav context
export const useBottomNav = (): BottomNavContextType => {
    const context = useContext(BottomNavContext)
    if (!context) {
        throw new Error('useBottomNav must be used within a BottomNavProvider')
    }
    return context
}

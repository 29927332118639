if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    console.error('Missing Google Maps API Key in environment variables')
}

// Type definition for Google Maps configuration
export type GoogleMapsConfig = {
    apiKey: string
    mapId: string
}

// Retrieve configuration from environment variables
const googleMapsConfig: GoogleMapsConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    mapId: 'cf858d4c4e9250a7',
}

export default googleMapsConfig

// see https://supabase.com/docs/guides/auth/auth-helpers/auth-ui
// see  https://www.restack.io/docs/supabase-knowledge-supabase-auth-ui-guide

import {useEffect} from 'react'

import {useNavigate, useLocation} from 'react-router-dom'
import {Route} from '../../router'

import {Auth} from '@supabase/auth-ui-react'
import {ThemeSupa} from '@supabase/auth-ui-shared'
//for future decoration
//import {getLoginCustomTheme} from './LoginCustomTheme'

import {useTranslation} from 'react-i18next'
// gernman,frenchm spanisch, japanese transaltion for Auth
// see https://github.com/supabase-community/auth-ui/tree/main/localization

import {supabaseClient} from '../../config/supabase'
import {useAuth} from '../../hooks/Auth'

import {useBottomNav} from '../../hooks/BottomNavControl'

const Login = () => {
    const {loading, session, user, event} = useAuth()

    // language support
    const {t} = useTranslation()

    // Routes where Navbar and BottomNav should NOT be shown
    const navigate = useNavigate()
    const location = useLocation()
    const {hideBottomNav} = useBottomNav()

    useEffect(() => {
        if (!loading) {
            if (!session && location.pathname !== Route.LOGIN) {
                // navigate unauthenticated users to the public Root route
                navigate(Route.ROOT, {replace: true})
                console.log('Login -> Root')
            } else if (session && user) {
                // navigate authenticated users to the Home route
                navigate(Route.HOME, {replace: true})
                console.log('Login (session) -> Home')
            }
        }
    }, [loading, session, navigate, location.pathname])

    useEffect(() => {
        // navigate signed_in user to Home route
        if (event === 'SIGNED_IN') {
            navigate(Route.HOME, {replace: true})
            console.log('Login (SIGNED_IN) -> Home')
        }
    }, [event, navigate])

    useEffect(() => {
        hideBottomNav()
    }, [hideBottomNav])

    return (
        <div className="PageContainer">
            <Auth
                supabaseClient={supabaseClient}
                providers={['facebook', 'google']} // Facebook-Provider hinzufügen
                appearance={{theme: ThemeSupa}}
                //theme="dark"
                view="sign_in"
                localization={{
                    variables: {
                        sign_in: {
                            email_label: t('auth.login.email_label'),
                            email_input_placeholder: t(
                                'auth.login.your-email-address-placeholder',
                            ),
                            password_label: t('auth.login.password_label'),
                            password_input_placeholder: '',
                            button_label: t('auth.login.button_label'),
                            social_provider_text: t(
                                'auth.login.sign-in-with-provider',
                            ),
                            link_text: t('auth.login.linktext'),
                        },
                        sign_up: {
                            link_text: t('auth.signup.link_text'),
                        },
                        forgotten_password: {
                            link_text: t('auth.forget_password.link_text'),
                        },
                    },
                }}
                // only for password-less social logins
                redirectTo={process.env.REACT_APP_BASE_START_URL}
                // see https://supabase.com/docs/guides/auth/redirect-urls
                // When using passwordless sign-ins or third-party providers, the Supabase client library
                // methods provide a redirectTo parameter to specify where to redirect the user to after authentication.
                // By default, the user will be redirected to the SITE_URL but you can modify the SITE_URL or add additional redirect
                // URLs to the allow list. Once you've added necessary URLs to the allow list, you can specify the URL you
                // want the user to be redirected to in the redirectTo parameter.
            />
        </div>
    )
}

export default Login

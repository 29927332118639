import {Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

const Chat = () => {
    const {t} = useTranslation()

    return (
        <div className="PageContainer">
            <Typography variant="h1">Chat</Typography>
            <Typography variant="body1">
                {t('ChatSlogan', {appName: 'Noonout'})}
            </Typography>
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                {t('ChatAnnouncement')}
            </Typography>
        </div>
    )
}

export default Chat

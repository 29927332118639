import React, {useEffect, useState} from 'react'

import {Box} from '@mui/material'

const StandaloneCheck: React.FC = () => {
    const [isStandalone, setIsStandalone] = useState<boolean>(false)
    const [platform, setPlatform] = useState<string>('')

    useEffect(() => {
        const checkStandaloneMode = () => {
            const userAgent = window.navigator.userAgent.toLowerCase()

            // Check for iOS standalone mode
            if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
                setPlatform('iOS')
                setIsStandalone((window.navigator as any).standalone === true)
            }
            // Check for Android standalone mode
            else if (userAgent.includes('android')) {
                setPlatform('Android')
                setIsStandalone(
                    window.matchMedia('(display-mode: standalone)').matches,
                )
            } else {
                setPlatform('Unknown')
                setIsStandalone(false)
            }
        }

        checkStandaloneMode()
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center', // Centers the content vertically in its row
                alignItems: 'center',
                color: 'black',
                width: '100%',
                maxWidth: 600,
                margin: 'auto',
                padding: 2,
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: '#e0f7fa',
                gap: 2, // Adds spacing between items
            }}
        >
            <h1>PWA Standalone Mode Check</h1>
            {platform ? (
                <p>
                    Platform: <strong>{platform}</strong>
                </p>
            ) : null}
            {isStandalone ? (
                <p style={{color: 'green'}}>
                    The app is running in <strong>standalone mode</strong>.
                </p>
            ) : (
                <p style={{color: 'red'}}>
                    The app is <strong>not in standalone mode</strong>.
                </p>
            )}
        </Box>
    )
}

export default StandaloneCheck

import React from 'react'
import {Box, Button, Typography} from '@mui/material'

const UpdateApp: React.FC = () => {
    const handleReload = (): void => {
        window.location.reload() // Force reload to get the new version
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Centers the content vertically in its row
                alignItems: 'center',
                color: 'black',
                width: '100%',
                maxWidth: 600,
                margin: 'auto',
                padding: 2,
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: '#e0f7fa',
                gap: 2, // Adds spacing between items
            }}
        >
            <Button
                variant="contained"
                sx={{
                    'fontSize': {
                        xs: '0.8rem',
                        sm: '1.0rem',
                        md: '1.5rem',
                    },
                    'padding': {
                        xs: '2px 2px',
                        sm: '5px 2px',
                        md: '8px 8px',
                    },
                    'width': 'auto',
                    'minWidth': '150px',
                    'height': '5vh',
                    'textAlign': 'center',
                    'whiteSpace': 'nowrap',
                    'backgroundColor': '#ffffff',
                    'borderRadius': '25px',
                    'cursor': 'pointer',
                    '&:hover': {
                        backgroundColor: '#0056b3',
                    },
                    'transition': 'background-color 0.3s ease',
                    'border': '2px solid #0056b3',
                    'marginBottom': 'env(safe-area-inset-bottom)', // Button bleibt sichtbar
                }}
                onClick={handleReload}
            >
                Reload
            </Button>
            <Typography
                variant="h4"
                sx={{
                    fontSize: {xs: '0.5rem', sm: '0.8rem'},
                    mb: {xs: 2, sm: 4},
                }}
                gutterBottom
            >
                Reload latest app version
            </Typography>
        </Box>
    )
}

export default UpdateApp

import React from 'react'

import {Button, Typography} from '@mui/material'

import {OnboardingContainer, FormElementWrapper} from './StyledComponents'

interface Step3Props {
    onSubmit: () => void
}

const Step3: React.FC<Step3Props> = ({onSubmit}) => {
    const handleSubmit = () => {
        onSubmit()
    }

    return (
        <div>
            <OnboardingContainer>
                <FormElementWrapper>
                    <Typography variant="body1">Step 3 </Typography>
                </FormElementWrapper>

                {/* Submit Button */}
                <FormElementWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </FormElementWrapper>
            </OnboardingContainer>
        </div>
    )
}

export default Step3

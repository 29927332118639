import React, {useState, useEffect} from 'react'

import {Typography} from '@mui/material'

import {useNavigate} from 'react-router-dom'
import {Route} from '../../router'

import {useAuth} from '../../hooks/Auth'

import {useBottomNav} from '../../hooks/BottomNavControl'

import Step1 from '../../components/onboarding/Step1'
import Step2 from '../../components/onboarding/Step2'
import Step3 from '../../components/onboarding/Step3'
import Step4 from '../../components/onboarding/Step4'

import OnboardingData from '../../types/OnboardingData'

const Onboarding = () => {
    const {onboardingStep, setOnboardingStep, user} = useAuth()

    const {hideBottomNav} = useBottomNav()

    const navigate = useNavigate()

    const [onboardingData, setOnboardingData] = useState<OnboardingData | null>(
        null,
    )

    const userImageUrl = null

    const handleOnboardingStep1Submit = (data: OnboardingData) => {
        console.log('Onboarding Data received:', JSON.stringify(data))

        // Hier könntest du die Daten an eine API senden oder in den Zustand speichern
        // Beispiel für das Speichern im Zustand:
        setOnboardingData(data)

        // Beispiel für das Speichern in LocalStorage:
        localStorage.setItem('onboarding_data', JSON.stringify(data))
        setOnboardingStep('1')
    }

    const handleOnboardingStep2Submit = () => {
        setOnboardingStep('2')
    }

    const handleOnboardingStep3Submit = () => {
        setOnboardingStep('done')
        // navigate to home
        navigate(Route.HOME, {replace: true})
        console.log('Onboarding -> Home')
    }

    useEffect(() => {
        hideBottomNav()
    }, [hideBottomNav])

    return (
        <div className="PageContainer">
            <Typography variant="h1">Onboarding</Typography>

            {/* Conditional rendering for each step */}
            {onboardingStep === '0' && user && user.user_metadata && (
                <Step1
                    userImageUrl={user.user_metadata.picture || null}
                    onSubmit={handleOnboardingStep1Submit}
                />
            )}
            {onboardingStep === '1' && (
                <Step2 onSubmit={handleOnboardingStep2Submit} />
            )}
            {onboardingStep === '2' && (
                <Step3 onSubmit={handleOnboardingStep3Submit} />
            )}
        </div>
    )
}

export default Onboarding

import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import enJSON from './locale/en.json'
import deJSON from './locale/de.json'

i18n.use(initReactI18next).init({
    resources: {
        en: {translation: enJSON},
        de: {translation: deJSON},
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
        escapeValue: false, // React already escapes values
    },
})

export default i18n

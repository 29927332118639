import React from 'react'
import {Navigate} from 'react-router-dom'

import {useAuth} from '../hooks/Auth'

const ProtectedRoute = ({children}: {children: JSX.Element}) => {
    const {session, loading} = useAuth()

    if (loading) {
        return <div>Loading ..</div>
    }

    if (!session) {
        return <Navigate to="/" replace />
    }

    return children
}

export default ProtectedRoute

import {createTheme, responsiveFontSizes} from '@mui/material/styles'
import '@fontsource/roboto' // Defaults to weight 400

export enum ThemeColors {
    backgroundLight = '#f8f9fa', // Light background color
    backgroundDark = '#212529', // Dark background color
    primaryLight = '#ffffff', // White primary color for light mode
    primaryDark = '#343a40', // Dark primary color for dark mode
    secondaryLight = '#0056b3', // Blue for light mode
    secondaryDark = '#00aaff', // Lighter blue for dark mode
    textLight = '#343a40', // Darker text for light mode
    textDark = '#f8f9fa', // Lighter text for dark mode
    textPrimaryDark = '#ffffff', // Bright text for primary content in dark mode
    textSecondaryDark = '#b0bec5', // Subtle gray for secondary content in dark mode
    tertiary = '#212529', // Darker color for text
    success = '#28a745',
    warning = '#ffc107',
    error = '#dc3545',
    info = '#17a2b8',
    black = '#343a40',
    white = '#ffffff',
}

const baseStyles = {
    height: '50px', // Einheitliche Höhe für alle Eingabefelder
    fontSize: '16px', // Einheitliche Schriftgröße
    borderRadius: '8px', // Abgerundete Ecken
    padding: '0 12px', // Einheitliche Padding
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Standard-Schatten
    transition: 'all 0.3s ease', // Sanfte Übergänge
}

const focusStyles = {
    boxShadow: '0 0 0 4px rgba(25, 118, 210, 0.3)', // Blaue Umrandung bei Fokus
}

const hoverStyles = {
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', // Leicht verstärkter Schatten bei Hover
}

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: ThemeColors.backgroundLight,
        },
        primary: {
            main: ThemeColors.primaryLight,
        },
        secondary: {
            main: ThemeColors.secondaryLight,
        },
        text: {
            primary: ThemeColors.textLight,
            secondary: ThemeColors.secondaryLight,
            disabled: 'rgba(0, 0, 0, 0.38)', // Farben für deaktivierte Inhalte
        },
        success: {
            main: ThemeColors.success,
        },
        warning: {
            main: ThemeColors.warning,
        },
        error: {
            main: ThemeColors.error,
        },
        info: {
            main: ThemeColors.info,
        },
    },
    typography: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        htmlFontSize: 18, // Basis-Root-Schriftgröße auf 18px
        h1: {fontSize: '2.5rem', fontWeight: 700, lineHeight: 1.2},
        h2: {fontSize: '2rem', fontWeight: 600, lineHeight: 1.3},
        h3: {fontSize: '1.75rem', fontWeight: 500, lineHeight: 1.4},
        body1: {fontSize: '1rem', lineHeight: 1.5},
        body2: {fontSize: '0.875rem', lineHeight: 1.43},
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: ThemeColors.primaryLight,
                    color: ThemeColors.textLight,
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    backgroundColor: ThemeColors.primaryLight,
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    'color': ThemeColors.tertiary,
                    '&.Mui-selected': {
                        color: ThemeColors.secondaryLight, // Visible color for selected icon
                    },
                },
                label: {
                    'fontSize': '0.75rem',
                    '&.Mui-selected': {
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        color: ThemeColors.secondaryLight, // Visible color for selected label
                    },
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {marginBottom: '1rem'},
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    ...baseStyles,
                    'backgroundColor': ThemeColors.backgroundLight,
                    '&:hover': hoverStyles,
                    '&.Mui-focused': focusStyles,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    ...baseStyles,
                    'backgroundColor': ThemeColors.backgroundLight,
                    '&:hover': hoverStyles,
                    '&.Mui-focused': focusStyles,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    ...baseStyles,
                    'backgroundColor': ThemeColors.backgroundLight,
                    '&:hover': hoverStyles,
                    '&.Mui-focused': focusStyles,
                },
                select: {
                    padding: '12px 12px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    'fontSize': '16px',
                    'padding': '10px 16px',
                    'transition': 'background-color 0.3s ease',
                    '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.1)',
                    },
                    '&.Mui-selected': {
                        'backgroundColor': 'rgba(25, 118, 210, 0.2)',
                        '&:hover': {
                            backgroundColor: 'rgba(25, 118, 210, 0.3)',
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    'color': ThemeColors.secondaryLight,
                    '&.Mui-checked': {
                        color: ThemeColors.secondaryLight,
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.1)',
                    },
                },
            },
        },
    },
})

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: ThemeColors.backgroundDark,
        },
        primary: {
            main: ThemeColors.primaryDark,
        },
        secondary: {
            main: ThemeColors.secondaryDark,
        },
        text: {
            primary: ThemeColors.textDark,
            secondary: ThemeColors.secondaryDark,
        },
        success: {
            main: ThemeColors.success,
        },
        warning: {
            main: ThemeColors.warning,
        },
        error: {
            main: ThemeColors.error,
        },
        info: {
            main: ThemeColors.info,
        },
    },
    typography: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        htmlFontSize: 18, // Basis-Root-Schriftgröße auf 18px
        h1: {fontSize: '2.5rem', fontWeight: 700, lineHeight: 1.2},
        h2: {fontSize: '2rem', fontWeight: 600, lineHeight: 1.3},
        h3: {fontSize: '1.75rem', fontWeight: 500, lineHeight: 1.4},
        body1: {fontSize: '1rem', lineHeight: 1.5},
        body2: {fontSize: '0.875rem', lineHeight: 1.43},
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: ThemeColors.primaryDark,
                    color: ThemeColors.textDark,
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    backgroundColor: ThemeColors.primaryDark,
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    'color': ThemeColors.textSecondaryDark, // Unselected icon color
                    '&.Mui-selected': {
                        color: ThemeColors.secondaryDark, // Selected icon color
                    },
                },
                label: {
                    'fontSize': '0.75rem',
                    'color': ThemeColors.textSecondaryDark, // Unselected label color
                    '&.Mui-selected': {
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        color: ThemeColors.secondaryDark, // Selected label color
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {marginBottom: '1rem'},
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    ...baseStyles,
                    'backgroundColor': ThemeColors.primaryDark,
                    '&:hover': hoverStyles,
                    '&.Mui-focused': focusStyles,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    ...baseStyles,
                    'backgroundColor': ThemeColors.primaryDark,
                    '&:hover': hoverStyles,
                    '&.Mui-focused': focusStyles,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    ...baseStyles,
                    'backgroundColor': ThemeColors.primaryDark,
                    '&:hover': hoverStyles,
                    '&.Mui-focused': focusStyles,
                },
                select: {
                    padding: '12px 12px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    'fontSize': '16px',
                    'padding': '10px 16px',
                    'transition': 'background-color 0.3s ease',
                    '&:hover': {
                        backgroundColor: 'rgba(144, 202, 249, 0.1)',
                    },
                    '&.Mui-selected': {
                        'backgroundColor': 'rgba(144, 202, 249, 0.2)',
                        '&:hover': {
                            backgroundColor: 'rgba(144, 202, 249, 0.3)',
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    'color': ThemeColors.textSecondaryDark,
                    '&.Mui-checked': {
                        color: ThemeColors.textSecondaryDark,
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(144, 202, 249, 0.1)',
                    },
                },
            },
        },
    },
})

const responsiveLightTheme = responsiveFontSizes(lightTheme)
const responsiveDarkTheme = responsiveFontSizes(darkTheme)

export {responsiveLightTheme, responsiveDarkTheme}

import React from 'react'
import ReactDOM from 'react-dom/client'

//import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import {register as registerServiceWorker} from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import {RouterProvider} from 'react-router-dom'
import {router} from './router'
import {AuthProvider} from './hooks/Auth'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// checkout workbox documentation
/*
serviceWorkerRegistration.register({
  onUpdate: (e) => {
    const { waiting: { postMessage = null } = {} as any, update } = e || {};
    if (postMessage) {
      postMessage({ type: 'SKIP_WAITING' });
    }
    update().then(() => {
      window.location.reload();
    });
  },
});
*/
registerServiceWorker()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import HomeIcon from '@mui/icons-material/Home'
import ChatIcon from '@mui/icons-material/Chat'
import PlaceIcon from '@mui/icons-material/Place'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import PersonIcon from '@mui/icons-material/Person'
import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useBottomNav} from '../../../hooks/BottomNavControl'

import {Route} from '../../../router'

import styles from './BottomNav.module.css'

import BottomNavProps from '../../../types/BottomNavProps'

const BottomNav: React.FC<BottomNavProps> = ({setBottomNavHeight}) => {
    const {isVisible} = useBottomNav() // Get visibility state from context
    const [value, setValue] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        // Aktualisiert die Höhe basierend auf der Sichtbarkeit
        setBottomNavHeight(isVisible ? '80px' : '0px')
    }, [isVisible, setBottomNavHeight])

    // Dont render BottomNav if not visible
    // Nicht rendern, solange isVisible nicht definiert ist oder auf false steht
    if (typeof isVisible === 'undefined' || !isVisible) return null

    return (
        <Box
            className={styles.Container}
            style={{
                transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
                transition: 'transform 0.3s ease-in-out',
                height: '80px',
            }}
        >
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue)
                    switch (newValue) {
                        case 0:
                            navigate(Route.HOME)
                            break
                        case 1:
                            navigate(Route.SEARCH)
                            break
                        case 2:
                            navigate(Route.MAP)
                            break
                        case 3:
                            navigate(Route.CHAT)
                            break
                        case 4:
                            navigate(Route.PROFILE)
                            break
                    }
                }}
            >
                <BottomNavigationAction icon={<HomeIcon />} />
                <BottomNavigationAction icon={<SearchOutlinedIcon />} />
                <BottomNavigationAction icon={<PlaceIcon />} />
                <BottomNavigationAction icon={<ChatIcon />} />
                <BottomNavigationAction icon={<PersonIcon />} />
            </BottomNavigation>
        </Box>
    )
}

export default BottomNav

import React, {useState, useCallback, useRef, useEffect} from 'react'

import {
    APIProvider,
    Map,
    Pin,
    AdvancedMarker,
    InfoWindow,
    useApiLoadingStatus,
    APILoadingStatus,
} from '@vis.gl/react-google-maps'

// Doc:  https://visgl.github.io/react-google-maps/docs
// https://developers.google.com/maps/documentation/javascript/examples/rgm-basic-map
// <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />

import googleMapsConfig from '../../config/googlemaps'

type GoogleMapWithLocationProps = {
    lat: number // Latitude for the map's marker
    lng: number // Longitude for the map's marker
    zoom: number // Zoom factor
}

const GoogleMapWithLocation: React.FC<GoogleMapWithLocationProps> = ({
    lat,
    lng,
    zoom,
}) => {
    const {apiKey, mapId} = googleMapsConfig

    const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
        null,
    )

    const [infoWindowShown, setInfoWindowShown] = useState(false)

    const status = useApiLoadingStatus()

    useEffect(() => {
        if (status === APILoadingStatus.FAILED) {
            console.log('Google-maps : APILoadingStatus.FAILED :(')
            return
        }
    }, [status])

    // clicking the marker will toggle the infowindow
    const handleMarkerClick = useCallback(
        () => setInfoWindowShown((isShown) => !isShown),
        [],
    )

    // if the maps api closes the infowindow, we have to synchronize our state
    const handleClose = useCallback(() => setInfoWindowShown(false), [])

    return (
        <div className="PageContainer">
            <APIProvider apiKey={apiKey}>
                <Map
                    style={{width: '100vw', height: '100vh'}}
                    center={{lat: lat, lng: lng}}
                    zoom={zoom}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    mapId={mapId}
                >
                    <AdvancedMarker
                        position={{lat: lat, lng: lng}}
                        ref={markerRef}
                        onClick={handleMarkerClick}
                    >
                        {infoWindowShown && markerRef && (
                            <InfoWindow
                                anchor={markerRef.current}
                                onClose={handleClose}
                                style={{
                                    fontFamily: 'Arial, sans-serif', // Optional: Set font family
                                    fontSize: '11px', // Optional: Set font size
                                    color: 'black', // Set the font color to black
                                    padding: '1px', // Optional: Add some padding
                                }}
                            >
                                <div>
                                    <h3>Lunchdate</h3>
                                    <p>
                                        Some arbitrary content for the
                                        InfoWindow.
                                    </p>
                                </div>
                            </InfoWindow>
                        )}
                        <img
                            src={'https://noonout.com/logo.png'}
                            width={48}
                            height={48}
                        />
                    </AdvancedMarker>
                </Map>
            </APIProvider>
        </div>
    )
}

export default GoogleMapWithLocation

import styled from 'styled-components'
import {Box} from '@mui/material'

// Define a styled container for the onboarding page
export const OnboardingContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 10%;
    width: 100%;
    box-sizing: border-box;
    gap: 10px; /* Add spacing between elements */

    @media (min-width: 768px) {
        padding: 20px 15%;
    }

    @media (min-width: 1024px) {
        padding: 40px 20%;
    }
`

// Define a styled form control wrapper
export const FormElementWrapper = styled(Box)`
    width: 100%;
    max-width: 500px; /* Limit the width on larger screens */
    min-width: 300px; /* Ensure elements are not too narrow */
    box-sizing: border-box;

    & .MuiFormControl-root,
    & .MuiTextField-root,
    & .MuiSelect-root {
        width: 100%;
    }
`

import {useState, useEffect} from 'react'
import GoogleMapWithLocation from './GoogleMapWithLocations'

type Location = {
    latitude: number | null
    longitude: number | null
    zoom: number | null
}

const Map = () => {
    const [location, setLocation] = useState<Location>({
        // set a default to Berlin Mitte
        latitude: 52.520008,
        longitude: 13.404954,
        zoom: 16,
    })

    useEffect(() => {
        const getUserLocation = () => {
            console.log('Starting  GeoLocation ..')

            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        setLocation({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            zoom: 16,
                        })
                    },
                    (err: GeolocationPositionError) => {
                        console.log('Error GeoLocation ..' + err.message)
                    },
                    {
                        enableHighAccuracy: true, // Use GPS for high accuracy
                        timeout: 10000, // Timeout after 10 seconds
                        maximumAge: 0, // Do not use cached location
                    },
                )
            } else {
                console.log('Geolocation is not supported by your browser.')
            }
        }
        getUserLocation()
    }, [])

    return (
        <div>
            {location &&
                location.latitude &&
                location.longitude &&
                location.zoom && (
                    <GoogleMapWithLocation
                        lat={location.latitude}
                        lng={location.longitude}
                        zoom={location.zoom}
                    />
                )}
        </div>
    )
}

export default Map

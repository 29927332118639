export interface Position {
    lat: number
    lng: number
}

export interface Lunchdate extends Position {
    name: string
    dateTime: string // ISO 8601 format
    freeText: string
    pictureURL: string // URL for the image
    colorCode: string // Color code for styling (e.g., hex code)
}

/**
 * Generates a random lunchdate within 7 days and between 11 AM and 4 PM in the system timezone.
 * @param centerLat Latitude of the center point.
 * @param centerLng Longitude of the center point.
 * @param radius Radius around the center point (in meters).
 * @param name Name of the person or event.
 * @param freeText Free text or description.
 * @returns A Lunchdate object.
 */
export function generateLunchdate(
    centerLat: number,
    centerLng: number,
    radius: number,
    name: string,
    freeText: string,
    pictureURL: string, // New parameter for the picture URL
    colorCode: string, // New parameter for the color code
): Lunchdate {
    const earthRadius = 6371000 // Earth's radius in meters

    // Random angle (radians)
    const angle = Math.random() * 2 * Math.PI

    // Random distance (meters) within the radius
    const distance = Math.random() * radius

    // Offset in latitude and longitude
    const deltaLat =
        ((distance * Math.cos(angle)) / earthRadius) * (180 / Math.PI)
    const deltaLng =
        ((distance * Math.sin(angle)) /
            (earthRadius * Math.cos((centerLat * Math.PI) / 180))) *
        (180 / Math.PI)

    // Generate a random date and time within the next 7 days and between 11 AM - 4 PM
    const now = new Date()
    const randomDate = new Date()

    // Add a random number of days (0-6) within the next 7 days
    randomDate.setDate(now.getDate() + Math.floor(Math.random() * 7))

    // Random hour between 11 AM and 4 PM
    const randomHour = 11 + Math.floor(Math.random() * 5)

    // Random minute within the hour
    const randomMinute = Math.floor(Math.random() * 60)

    // Set the time
    randomDate.setHours(randomHour)
    randomDate.setMinutes(randomMinute)
    randomDate.setSeconds(0)
    randomDate.setMilliseconds(0)

    return {
        lat: centerLat + deltaLat,
        lng: centerLng + deltaLng,
        name: name,
        dateTime: randomDate.toISOString(), // Convert to ISO 8601 format
        freeText: freeText,
        pictureURL: pictureURL, // Added pictureURL
        colorCode: colorCode, // Added colorCode
    }
}

/**
 * Generates a list of 10 random lunchdates around a given latitude and longitude.
 * @param centerLat Latitude of the center point.
 * @param centerLng Longitude of the center point.
 * @param radius Radius around the center point (in meters).
 * @returns An array of 10 Lunchdate objects.
 */
export function generateLunchdates(
    centerLat: number,
    centerLng: number,
    radius: number,
): Lunchdate[] {
    const names = [
        'Alice',
        'Bob',
        'Charlie',
        'Diana',
        'Eve',
        'Frank',
        'Grace',
        'Hannah',
        'Ivan',
        'Jack',
    ]
    const freeTexts = [
        'Discuss project updates',
        'Team lunch',
        'Casual meetup',
        'Celebration',
        'Networking opportunity',
        'Planning session',
        'Brainstorming ideas',
        'Feedback session',
        'Quick catch-up',
        'Strategy meeting',
    ]

    const BASE_URL = `${window.location.origin}`

    // Example image URLs and color codes
    const pictureURLs = [
        `${BASE_URL}/profilepic_1.png`,
        `${BASE_URL}/profilepic_2.png`,
        `${BASE_URL}/profilepic_3.png`,
        `${BASE_URL}/profilepic_4.png`,
        `${BASE_URL}/profilepic_5.png`,
        `${BASE_URL}/profilepic_1.png`,
        `${BASE_URL}/profilepic_2.png`,
        `${BASE_URL}/profilepic_3.png`,
        `${BASE_URL}/profilepic_4.png`,
        `${BASE_URL}/profilepic_5.png`,
    ]

    const colorCodes = [
        '#FF6347',
        '#4682B4',
        '#32CD32',
        '#FFD700',
        '#FF69B4',
        '#8A2BE2',
        '#A52A2A',
        '#5F9EA0',
        '#D2691E',
        '#9ACD32',
    ]

    return names.map((name, index) =>
        generateLunchdate(
            centerLat,
            centerLng,
            radius,
            name,
            freeTexts[index],
            pictureURLs[index],
            colorCodes[index],
        ),
    )
}

import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {Box, Typography, Button} from '@mui/material'

import {useTranslation} from 'react-i18next'

import {Route} from '../../router/'
import {supabaseClient} from '../../config/supabase'

const Splashscreen: React.FC = () => {
    const navigate = useNavigate()

    // language support
    const {t} = useTranslation()

    useEffect(() => {
        // Fetch initial session
        const fetchSession = async () => {
            const {
                data: {session},
            } = await supabaseClient.auth.getSession()

            if (session) {
                navigate(Route.HOME, {replace: true})
            }
        }

        fetchSession()
    }, [navigate])

    const handleLogin = () => {
        navigate(Route.LOGIN)
    }

    const handleSignUp = () => {
        navigate(Route.SIGNUP)
    }

    return (
        // level 0
        <Box
            sx={{
                display: 'grid',
                gridTemplateRows: '1fr auto', // Two rows: one for content and one for the button
                height: '100dvh', // Full viewport height
                backgroundImage: 'url(/background_s.jpg)', // Adjust the background path
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                textAlign: 'center',
                position: 'relative', // Keeps layout in place
                zIndex: 0, // Background image has the lowest z-index
            }}
        >
            {/* Logo and Title Area */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center', // Centers the content vertically in its row
                    alignItems: 'center',
                    color: 'white',
                    textShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                    padding: '20px', // Add padding for spacing
                    overflowY: 'auto', // Ensure content scrolls if it overflows
                    zIndex: 1, //
                }}
            >
                <Box
                    component="img"
                    src="/logo.png" // Adjust the logo path
                    alt="Logo"
                    sx={{
                        width: '300px',
                        height: 'auto',
                        mb: 4, // Margin bottom for spacing
                    }}
                />
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: {xs: '2.5rem', sm: '3.5rem'},
                        fontWeight: 'bold',
                        mb: {xs: 2, sm: 4},
                    }}
                >
                    {t('SplashHeroSlogan')}
                </Typography>
                {/* Fixed Button */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px', // Adds padding around the button row
                        gap: {xs: 2, sm: 3, md: 4}, // Responsive gap between buttons
                        zIndex: 1, //
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            'fontSize': {
                                xs: '1.1rem',
                                sm: '1.4rem',
                                md: '1.7rem',
                            },
                            'padding': {
                                xs: '10px 20px',
                                sm: '12px 24px',
                                md: '14px 28px',
                            },
                            'width': 'auto',
                            'minWidth': '150px',
                            'height': '5vh',
                            'textAlign': 'center',
                            'whiteSpace': 'nowrap',
                            'color': 'black', // override team text color
                            'backgroundColor': '#ffffff',
                            'borderRadius': '25px',
                            'cursor': 'pointer',
                            '&:hover': {
                                backgroundColor: '#0056b3',
                            },
                            'transition': 'background-color 0.3s ease',
                            'border': '2px solid #0056b3',
                            'marginBottom': 'env(safe-area-inset-bottom)', // Button bleibt sichtbar
                        }}
                        onClick={handleLogin}
                    >
                        {t('SplashLogin')}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            'fontSize': {
                                xs: '1.2rem',
                                sm: '1.5rem',
                                md: '1.7rem',
                            },
                            'padding': {
                                xs: '10px 20px',
                                sm: '12px 24px',
                                md: '14px 28px',
                            },
                            'width': 'auto',
                            'minWidth': '150px',
                            'height': '5vh',
                            'textAlign': 'center',
                            'whiteSpace': 'nowrap',
                            'color': 'black', // override team text color
                            'backgroundColor': '#ffffff',
                            'borderRadius': '25px',
                            'cursor': 'pointer',
                            '&:hover': {
                                backgroundColor: '#0056b3',
                            },
                            'transition': 'background-color 0.3s ease',
                            'border': '2px solid #0056b3',
                            'marginBottom': 'env(safe-area-inset-bottom)', // Button bleibt sichtbar
                        }}
                        onClick={handleSignUp}
                    >
                        {t('SplashSignup')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Splashscreen

import React, {useState, useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {CssBaseline} from '@mui/material'

import Navbar from './components/organisms/Navbar/Navbar'
import BottomNav from './components/organisms/BottomNav/BottomNav'

import {BottomNavProvider} from './hooks/BottomNavControl'
import {useAuth} from './hooks/Auth'
import {ThemeProviderWrapper} from './hooks/ThemeMode'

//app main layout
import './App.css'

// app locales
import './i18n.ts'
import {useTranslation} from 'react-i18next'

const App: React.FC = () => {
    // authuser
    const {loading, session} = useAuth()

    // locales
    const {i18n} = useTranslation()

    // Routes where Navbar and BottomNav should NOT be shown
    const location = useLocation()
    const excludedRoutes = ['/'] // Add splash screen route or other public routes here
    const isProtectedRoute = !excludedRoutes.includes(location.pathname)

    // hide bottomNav dynamic
    const [bottomNavHeight, setBottomNavHeight] = useState('80px')

    useEffect(() => {
        // Funktion zum Setzen der Sprache beim Start
        const setAppLanguage = () => {
            const storedLanguage = localStorage.getItem('app_language')

            // Wenn im localStorage eine Sprache gespeichert ist, diese verwenden
            if (
                storedLanguage &&
                (storedLanguage === 'en' || storedLanguage === 'de')
            ) {
                i18n.changeLanguage(storedLanguage)
            } else {
                // Wenn keine gültige Sprache gefunden wurde, die Standard-Sprache setzen
                const defaultLanguage = 'en' // Standard-Sprache (kann auch 'de' sein)
                i18n.changeLanguage(defaultLanguage)
                localStorage.setItem('app_language', defaultLanguage) // Speichern im localStorage
            }
        }
        setAppLanguage()
    }, [i18n]) // Nur einmal beim Initialisieren der App aufrufen

    return (
        <div
            className="App"
            style={
                {
                    '--bottom-nav-height': bottomNavHeight,
                } as React.CSSProperties
            }
        >
            <ThemeProviderWrapper>
                <CssBaseline />
                <BottomNavProvider>
                    <div className="AppContainer">
                        {session && isProtectedRoute && <Navbar />}
                        <Outlet />
                        {session && isProtectedRoute && (
                            <BottomNav
                                setBottomNavHeight={setBottomNavHeight}
                            />
                        )}
                    </div>
                </BottomNavProvider>
            </ThemeProviderWrapper>
        </div>
    )
}

export default App

import React, {useState} from 'react'
import {Box, Button, Typography} from '@mui/material'

type Location = {
    latitude: number | null
    longitude: number | null
}

const GetLocation: React.FC = () => {
    const [location, setLocation] = useState<Location>({
        latitude: null,
        longitude: null,
    })
    const [error, setError] = useState<string | null>(null)

    const getUserLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    })
                    setError(null) // Clear any previous errors
                },
                (err: GeolocationPositionError) => {
                    setError(err.message)
                },
                {
                    enableHighAccuracy: true, // Use GPS for high accuracy
                    timeout: 10000, // Timeout after 10 seconds
                    maximumAge: 0, // Do not use cached location
                },
            )
        } else {
            setError('Geolocation is not supported by your browser.')
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Centers the content vertically in its row
                alignItems: 'center',
                color: 'black',
                width: '100%',
                maxWidth: 600,
                margin: 'auto',
                padding: 2,
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: '#e0f7fa',
                gap: 2, // Adds spacing between items
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2, // Space between the button and the other content
                }}
            >
                <Button
                    variant="contained"
                    onClick={getUserLocation}
                    sx={{
                        'fontSize': {
                            xs: '0.8rem',
                            sm: '1.0rem',
                            md: '1.5rem',
                        },
                        'padding': {
                            xs: '2px 2px',
                            sm: '5px 2px',
                            md: '8px 8px',
                        },
                        'width': 'auto',
                        'minWidth': '150px',
                        'height': '5vh',
                        'whiteSpace': 'nowrap',
                        'backgroundColor': '#ffffff',
                        'borderRadius': '25px',
                        'cursor': 'pointer',
                        '&:hover': {
                            backgroundColor: '#0056b3',
                        },
                        'transition': 'background-color 0.3s ease',
                        'border': '2px solid #0056b3',
                        'mb': 2,
                        'marginBottom': 'env(safe-area-inset-bottom)', // Button bleibt sichtbar
                    }}
                >
                    Get Location
                </Button>

                {location.latitude !== null && location.longitude !== null ? (
                    <Box
                        sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            borderRadius: 2,
                            backgroundColor: '#f5f5f5',
                            minWidth: '120px',
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="body2">
                            Latitude: {location.latitude}
                        </Typography>
                        <Typography variant="body2">
                            Longitude: {location.longitude}
                        </Typography>
                    </Box>
                ) : error ? (
                    <Box
                        sx={{
                            padding: 1,
                            border: '1px solid #f44336',
                            borderRadius: 2,
                            backgroundColor: '#ffebee',
                            minWidth: '120px',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: {xs: '0.5rem', sm: '0.8rem'},
                                mb: {xs: 2, sm: 4},
                                color: 'red',
                            }}
                        >
                            Error: {error}
                        </Typography>
                    </Box>
                ) : (
                    <Box>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: {xs: '0.5rem', sm: '0.8rem'},
                                mb: {xs: 2, sm: 4},
                            }}
                        >
                            No location available.
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default GetLocation

import React, {useState, useEffect} from 'react'
import {
    Switch,
    FormControlLabel,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material'

import {useTranslation} from 'react-i18next'

import {useThemeContext} from '../../hooks/ThemeMode'

const UserPreferences: React.FC = () => {
    const [pushNotifications, setPushNotifications] = useState<boolean>(true)
    const {t, i18n} = useTranslation()
    const {isDarkMode, toggleTheme} = useThemeContext()

    // Lokale Spracheinstellungen
    const [currentLanguage, setCurrentLanguage] = useState<string>('')

    // Funktion zum Setzen der Sprache aus localStorage oder navigator
    useEffect(() => {
        const storedLanguage = localStorage.getItem('app_language')

        // Wenn eine Sprache im localStorage gespeichert ist
        if (storedLanguage) {
            setCurrentLanguage(storedLanguage)
            i18n.changeLanguage(storedLanguage)
        } else {
            // Falls keine Sprache im localStorage vorhanden ist, die Sprache aus navigator nehmen
            const browserLanguage = navigator.language.split('-')[0] // z.B. "de-DE" -> "de"
            const validLanguages = ['en', 'de']

            // Wenn der Browser eine gültige Sprache hat, setzen wir sie
            if (validLanguages.includes(browserLanguage)) {
                setCurrentLanguage(browserLanguage)
                i18n.changeLanguage(browserLanguage)
            } else {
                // Wenn keine gültige Sprache gefunden wurde, die Standard-Sprache von i18next verwenden
                const defaultLanguage = i18n.language || 'en'
                setCurrentLanguage(defaultLanguage)
                i18n.changeLanguage(defaultLanguage)
            }

            // Speichern der Sprache im localStorage
            localStorage.setItem('app_language', i18n.language)
        }
    }, [i18n])

    // Funktion zum Ändern der Sprache
    const handleChangeLanguage = (newLanguage: string) => {
        setCurrentLanguage(newLanguage) // Update der lokalen State-Sprache
        i18n.changeLanguage(newLanguage) // Update der i18n-Sprache
        localStorage.setItem('app_language', newLanguage) // Speichern der Sprache im localStorage
    }

    return (
        <div style={{padding: '16px'}}>
            <Typography variant="h5" gutterBottom>
                User Preferences
            </Typography>

            {/* Push Notifications Toggle */}
            <FormControlLabel
                control={
                    <Switch
                        checked={pushNotifications}
                        onChange={() =>
                            setPushNotifications(!pushNotifications)
                        }
                    />
                }
                label="Push Notifications"
            />

            {/* Dark/Light Theme Toggle */}
            <FormControlLabel
                control={<Switch checked={isDarkMode} onChange={toggleTheme} />}
                label="Dark Mode"
            />

            {/* Language Selection */}
            <FormControl fullWidth style={{marginTop: '16px'}}>
                <InputLabel>Language</InputLabel>
                <Select
                    value={currentLanguage}
                    onChange={(e) =>
                        handleChangeLanguage(e.target.value as string)
                    }
                >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="de">Deutsch</MenuItem>
                    {/* Add more languages as needed */}
                </Select>
            </FormControl>
        </div>
    )
}

export default UserPreferences

import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import {Route} from '../../../router'
import userAvatarImage from '../../../assets/images/user.png'
import UserAvatar from '../../molecules/UserAvatar/UserAvatar'

import {useAuth} from '../../../hooks/Auth'

import styles from './Navbar.module.css'

const Navbar = () => {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

    const navigate = useNavigate()
    const {user, signOut} = useAuth()

    const handleSignOut = async () => {
        try {
            await signOut()
        } catch (error: any) {
            console.error('Error logging out:', error.message)
        }

        setAnchorElUser(null)
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <AppBar position="static" color="primary" className={styles.Navbar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{justifyContent: 'space-between'}}>
                    <Box
                        className={styles.FlexCentered}
                        onClick={() => navigate(Route.HOME)}
                    >
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Noonout
                        </Typography>
                    </Box>

                    {user &&
                    user.user_metadata &&
                    user.user_metadata.picture ? (
                        <UserAvatar
                            avatarImageAlt="user-avatar"
                            avatarImage={user.user_metadata.picture}
                            handleOpenUserMenu={handleOpenUserMenu}
                            handleCloseUserMenu={handleCloseUserMenu}
                            handleSignOut={handleSignOut}
                            anchorElUser={anchorElUser}
                        />
                    ) : (
                        <UserAvatar
                            avatarImageAlt="user-avatar"
                            avatarImage={userAvatarImage}
                            handleOpenUserMenu={handleOpenUserMenu}
                            handleCloseUserMenu={handleCloseUserMenu}
                            handleSignOut={handleSignOut}
                            anchorElUser={anchorElUser}
                        />
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Navbar
